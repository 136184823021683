.public-header {
  padding: 1.25rem 0 1rem;
  border-bottom: 0.125rem solid var(--interactive);
}
.public-header__logo {
  height: 3rem;
}
.public-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
