.patient {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.patient__main {
  @apply py-8;
  flex: 1;
  min-width: 0; /* support Chart JS responsiveness — https://github.com/chartjs/Chart.js/issues/4156#issuecomment-295180128 */
}
.patient__main > :last-child:after {
  /* scroll padding */
  content: "";
  display: block;
  height: 4rem;
}

/* Header */
.patient__header {
  padding-top: 1.5rem;
  background: var(--background);
}
.patient__breadcrumbs {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--gray);
}
.patient__header-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.patient__name {
  margin: 0;
}
