.users__list {
  list-style: none;
  padding: 0;
}
.users__user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  border-bottom: 2px solid var(--lightgray);
}

/* Add emails */
.users__add-email {
  display: flex;
}
.users__add-email:not(:first-of-type) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
