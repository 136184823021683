.activity-log__fields {
  list-style: none;
  margin: 1rem 0;
  padding-left: 1rem;
}
.activity-log__field {
  margin: 0.5rem 0;
}
.activity-log__user {
  color: var(--gray);
}
