.tab {
  @apply text-xl;
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding: 1rem 0.25rem;
  text-decoration: none;
  color: var(--interactive);
}
.tab:hover:not(.tab--active) {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.tab:active {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}
.tab--active {
  border-bottom-color: var(--interactive);
}
.tab--disabled:not(.tab--active) {
  opacity: 0.5;
  pointer-events: none;
}

/* Icon */
.tab__icon {
  margin-right: 0.25rem;
  font-size: 90%;
}

/* Tab Bar */
.tab-bar {
  border-bottom: 1px solid var(--pink);
  transform: translateY(-1px); /* Overlap border lines */
}
.tab-bar .tab {
  transform: translateY(1px); /* Overlap border lines */
}
.tab-bar__wrapper {
  display: flex;
  gap: 1.5rem;
}
