.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.6);
}
.modal__window {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
}
.modal__header,
.modal__footer {
  display: flex;
  align-items: center;
  padding: 1.25rem 2rem;
}
.modal__header {
  justify-content: space-between;
  border-bottom: 1px solid var(--pink);
  padding-right: 1.25rem;
}
.modal__footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--pink);
}
.modal__title {
  margin: 0;
}
