.text {
  margin: 0;
  font-weight: normal;
}
.text--link,
.text--link:hover {
  @apply hover:underline;
  color: var(--interactive);
}
.text--smallLight {
  color: var(--gray);
}
.text--smallDanger {
  color: var(--danger);
}
.text--h1 {
  @apply font-header text-3xl font-bold;
}
.text--h2 {
  @apply font-header text-2xl font-bold;
}
.text--h3 {
  @apply font-header text-xl font-bold;
}
.text--h4 {
  @apply font-header text-lg font-bold;
}
.text--body {
  color: var(--black);
}
