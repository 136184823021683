.wrapper {
  width: 100%;
  max-width: var(--wrapper-width);
}
.wrapper--wide {
  max-width: var(--wrapper-width-wide);
}
.wrapper--center {
  margin-left: auto;
  margin-right: auto;
}
.wrapper--pad {
  padding-left: 2rem;
  padding-right: 2rem;
}
