.login {
  margin: 2rem auto;
  padding: 1rem;
  width: 19.5rem;
  max-width: 100%;
}
.login__title {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.login__logo {
  display: block;
  max-width: 10rem;
  margin: 0 auto;
}
.login__form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
.login__feedback {
  margin: 0.5rem 0;
}
.login__password {
  /* Needs to be invisible to the user but still fill-able by a password manager */
  position: fixed;
  top: 100%;
  left: 100%;
  opacity: 0;
}
.login__password--visible {
  display: contents;
}
