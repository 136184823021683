html {
  --input-height: 2.25rem;
  --input-padding: 0.5em;
}

/* Fields */
.field {
  width: 100%;
  max-width: 17.5rem;
}
.field--wide {
  max-width: unset;
}
.field + .field:not(.field--short) {
  margin-top: 2rem;
}

/* Labels */
.label {
  display: inline-block;
}
.label--strong {
  display: block;
  font-weight: bold;
  margin: 0.5rem 0;
}

/* Input/Output/Select */
.input[type="text"],
.input[type="number"],
.input[type="date"],
.input[type="password"],
.input[type="email"],
.input[type="url"],
.input[type="tel"],
textarea.input,
.output,
.select__select {
  @apply border;
  @apply border-gray-80;
  height: var(
    --input-height
  ); /* Ensure consistent height even with e.g. Chrome's datepicker icon */
  line-height: 2rem;
  border-radius: 0.375rem;
  padding: 0 var(--input-padding);
  font-size: inherit;
  font-family: inherit;
}
.input[type="text"]:invalid:not(:focus),
.input[type="number"]:invalid:not(:focus),
.input[type="date"]:invalid:not(:focus),
.input[type="password"]:invalid:not(:focus),
.input[type="email"]:invalid:not(:focus),
.input[type="url"]:invalid:not(:focus),
.input[type="tel"]:invalid:not(:focus),
textarea.input:invalid:not(:focus),
.select:invalid:not(:focus) {
  border-color: var(--danger);
  box-shadow: 0px 0px 0px 1px var(--danger);
}
textarea.input {
  width: 100%;
  height: auto;
  line-height: 1.4;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  resize: vertical;
}
.input[type="radio"],
.input[type="checkbox"] {
  width: 1.125rem;
  height: 1.125rem;
  vertical-align: text-top;
}
.input[type="range"] {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 16rem;
}
/* Hide up/down stepper arrows on continuous number inputs - https://stackoverflow.com/a/4298216 */
.input[type="number"][step="any"]::-webkit-outer-spin-button,
.input[type="number"][step="any"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input[type="number"][step="any"] {
  -moz-appearance: textfield;
}
.input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: 0;
  padding-right: 0;
}
.output,
.input:read-only {
  background: var(--lightgray);
}
.input--silent:not(:hover):not(:invalid) {
  border-color: transparent;
}
.select {
  position: relative;
  display: flex;
}
.select--stretch {
  display: block;
}
.select--stretch .select__select {
  width: 100%;
}
.select__select {
  flex-grow: 1;
  appearance: none;
  padding-right: 2.5rem;
}
.select__icon {
  position: absolute;
  right: 0.9em;
  top: 50%;
  font-size: 0.875rem;
  transform: translateY(-50%);
  pointer-events: none;
}

/* TextField */
.textfield {
  position: relative;
}
.textfield__input {
  width: 100%;
}
.textfield__leading,
.textfield__trailing {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: var(--input-height);
}
.textfield__leading {
  left: var(--input-padding);
}
.textfield__trailing {
  right: var(--input-padding);
}
.textfield--with-leading .textfield__input {
  padding-left: 2.25em;
}
.textfield--with-trailing .textfield__input {
  padding-right: 2.25em;
}
.textfield__hints {
  display: flex;
  justify-content: flex-end;
  color: var(--darkgray);
}
.textfield__input:invalid:not(:focus) ~ .textfield__hints {
  color: var(--danger);
}
.textfield__invalid-help {
  flex: 1;
  display: none;
  margin-top: 0.25rem;
  font-style: italic;
}
.textfield__input:invalid ~ .textfield__hints .textfield__invalid-help {
  display: block;
}
.textfield__character-count {
  display: block;
  margin-top: 0.25rem;
  margin-bottom: -100%; /* margin-top from neighbouring Field can overlap this element */
}
.textfield__clear {
  position: relative;
  left: 0.25em;
  min-width: var(--min-interactive-mouse);
  text-align: center;
}

/* OutputField */
.outputfield__output {
  display: block;
}

/* RadioField, CheckboxField */
.radiofield__options,
.checkboxfield__options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.radiofield__option,
.checkboxfield__option {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0;
  padding: 0.375rem 0;
}

/* DiscreteRangeInput */
.discrete-range-input {
  padding-top: 0.25rem;
}
.discrete-range-input {
  margin: 0.5rem 0;
}
.discrete-range-input__input {
  margin-left: 0.4375rem;
}
.discrete-range-input__row {
  display: flex;
  align-items: center;
}
.discrete-range-input__output {
  margin-left: 0.5rem;
  font-style: italic;
}
