.help__header {
  padding: 1.5rem 0 2rem;
}
.help__main {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.help__side-nav {
  position: sticky;
  top: 0;
  padding-top: 1rem;
  padding-right: 2rem;
}
