.milestone {
  position: relative;
  text-align: center;
}
.milestone:before,
.milestone:after {
  content: "";
  display: none;
  position: absolute;
  top: var(--milestone-half-icon);
  transform: translateY(-50%);
  z-index: -1;
  width: calc(50% + calc(var(--milestone-gap) / 2));
  height: 0.1875rem;
  background: var(--hotpink);
}
.milestone:not(:last-child):after {
  display: block;
  left: calc(50% + var(--milestone-half-icon));
}
.milestone ~ .milestone:before {
  display: block;
  right: calc(50% + var(--milestone-half-icon));
}
.milestone__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: var(--milestone-icon);
  height: var(--milestone-icon);
  border: 0.1875rem solid transparent;
  border-radius: 1000px;
  background: var(--hotpink);
  color: white;
  font-size: calc(var(--milestone-icon) * 0.444);
}
.milestone--add .milestone__icon {
  background: transparent;
  color: var(--hotpink);
  border-color: var(--hotpink);
}
.milestone__label {
  display: block;
  margin-top: 0.5rem;
  color: var(--hotpink);
}
.milestone__date {
  width: 8.25rem;
  margin-top: 0.5rem;
  text-align: center;
}
.milestone__add {
  display: block;
  margin-top: 0.5rem;
}
