@media (min-width: 48rem) {
  .sidebar {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 15rem;
    background: var(--sidebar);
  }
  .sidebar__link {
    @apply text-lg;
    display: block;
    padding: 0.75rem 2rem;
    text-decoration: none;
    color: var(--interactive);
  }
  .sidebar__link--active {
    background: var(--sidebar-darker);
  }
  .sidebar__footer {
    margin-top: auto;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }
  .sidebar__manage-groups {
    flex-direction: column;
    align-items: flex-start;
  }
  .sidebar__logo {
    display: block;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
  .main {
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
  }
}

/* Sidebar */
.sidebar-icon {
  margin-right: 0.5rem;
}

/* User */
.user {
  display: flex;
  margin: 1.5rem 1rem;
}
.user .user__avatar {
  margin-top: 0.125rem;
  margin-right: 0.5rem;
  font-size: 2rem;
  color: var(--hotpink);
}
.user__info {
  flex: 1;
  overflow: hidden;
}
.user__email {
  display: block;
  color: var(--hotpink);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.user__group {
  display: block;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--interactive);
}
.user:hover .user__group {
  text-decoration: underline;
}
