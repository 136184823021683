.settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.settings__header {
  padding-top: 1.5rem;
}
.settings__header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.settings__title {
  margin: 0;
}

.settings__main {
  @apply py-8;
  flex: 1;
}

.settings__footer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 0;
}

/* Edit Tags */
.edit-tags__tag {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  border-bottom: 2px solid var(--lightgray);
}
.edit-tags__tag-name {
  flex: 1;
}
.edit-tags__draft {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.edit-tags__draft-name {
  margin-right: 0.5rem;
  max-width: 17.5rem;
}
.edit-tags__new {
  padding-top: 0.25rem;
}
