.helpshelf {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.helpshelf__header {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--pink);
}
.helpshelf__title {
  margin: 0.5rem 0;
}
.helpshelf__main {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.helpshelf__detail {
  flex: 1;
  padding: 2rem 0;
}
