.events__timing {
  display: block;
  padding-top: 0.75rem;
  color: var(--hotpink);
}
.events__name {
  margin: 0.25rem 0 0 0;
  padding-bottom: 0.75rem;
}

/* Event */
.event {
  @apply py-8;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.event__main {
  flex: 1;
}
.event .event__side-nav {
  top: 4rem;
}
.event [id] {
  scroll-margin-top: 6rem; /* Make room for sticky accordion summary */
}
