.icon {
  display: inline-block;
  min-width: var(--min-interactive-mouse);
  text-align: center;
}
.icon--interactive {
  color: var(--interactive);
}
.icon--danger {
  color: var(--danger);
}
