.locations {
  margin-bottom: 0.5rem;
}
.locations__location {
  display: block;
  padding: 0.5rem 0.75rem;
}
.locations__location:hover {
  background-color: var(--lightgray);
}
.locations__name {
  margin: 0 0 0.25rem 0;
}
.locations__address {
  color: var(--gray);
}
