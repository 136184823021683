.public-group {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.public-group__services {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.public-group__services-detail {
  flex: 1;
  width: 100%;
  padding: 2rem;
}
.public-group__title {
  margin: 0;
}
.public-group__share-link {
  margin-left: 2rem;
  width: 100%;
  max-width: 30rem;
  text-align: right;
}
.public-group__share-link-buttons {
  justify-content: flex-end;
}
.public-group__share-link-description {
  display: block;
  margin: 0.5rem 0 0.25rem 0;
}
.public-group__share-link-link {
  opacity: 0;
}
