@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables */
html {
  --small: 0.875rem;
  --text: black;
  --interactive: #3f0099;
  --background: white;
  --gray: #757575;
  --lightgray: #f5f5f5;
  --darkgray: #333;
  --pink: #faeffa;
  --hotpink: #ba3bba;
  --sidebar: #faeffa;
  --sidebar-darker: #f0d0f0;
  --danger: #cc2936;
  --text-on-danger: white;
  --orange: #fb8b24;
  --greendark: #3a7c5c;
  --greenlight: #ddece5;
  --blue: #008dd5;
  --min-interactive-mouse: 29px;
  --min-interactive-touch: 44px;
  --wrapper-width: 54rem;
  --wrapper-width-wide: 74rem;
}

/* Defaults */
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
}
#root {
  /* No scroll by default to enable flex layout */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
[id] {
  scroll-margin-top: 2rem; /* a little breathing room when you navigate to an element via #anchor */
}
img {
  max-width: 100%;
}

hr {
  margin: 3rem 0;
  border: 0;
  border-top: 2px solid var(--lightgray);
}
