.services {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.services__header {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--pink);
}
.services__header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.services__title {
  flex: 1;
  margin: 0.5rem 0;
}
.services__publish {
  margin-left: 1rem;
}
.services__main {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.services__detail {
  flex: 1;
  padding: 2rem;
}
.services__service-title {
  margin-bottom: 0.5rem;
}
.services__service-status {
  color: var(--hotpink);
}
.services__shelf-service-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.services__service-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem 0;
}
