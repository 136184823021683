.location {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.location__header {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--pink);
}
.location__header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location__breadcrumbs {
  display: block;
  color: var(--gray);
}
.location__title {
  margin: 0.5rem 0;
}
.location__main {
  flex: 1;
}
.location__main > :last-child {
  margin-bottom: 2rem;
}

/* File Field */
.filefield__img {
  max-width: 200px;
  max-height: 150px;
  margin: 0.4em 0 0.8em 0;
  transition: opacity 2s;
}
.filefield__img--loading {
  opacity: 0.5;
}

/* KeyNumbers */
.key-number {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.key-number__icon {
  margin: 0 0.5rem;
}
