.accordion {
  --accordion-trigger-width: 2.5rem;
}
.accordion:not(:last-child) {
  border-bottom: 1px solid var(--lightgray);
}
.accordion__summary {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--background);
}
.accordion__title {
  flex: 1;
}
.accordion--open .accordion__summary {
  border-bottom: 1px solid var(--lightgray);
}
.accordion__details {
  padding-left: var(--accordion-trigger-width);
}
.accordion__toggle {
  width: var(--accordion-trigger-width);
  min-width: 0;
  font-size: 1.5rem;
}
.accordion__toggle > * {
  transition: transform 0.25s ease-out;
}
.accordion--open .accordion__toggle > * {
  transform: rotate(90deg);
}
