.list-tile {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: inherit;
  cursor: pointer;
}
.list-tile:hover {
  background-color: var(--lightgray);
}
.list-tile__icon {
  width: 2rem;
  display: flex;
  justify-content: center;
}
.list-tile__title {
  flex: 1;
  color: var(--text);
}
/**
 * [aria-current] rules enable usage within `NavLink`
 */
[aria-current="page"] .list-tile {
  background-color: var(--lightgray);
  color: var(--interactive);
}
[aria-current="page"] .list-tile__title,
.list-tile:hover .list-tile__title {
  color: inherit;
}
