.save-state {
  display: block;
  color: var(--gray);
}
.save-state--saving {
  color: inherit;
}
.save-state--invalid {
  color: var(--danger);
}
