/* Goals */
.goals__new {
  margin-top: 1.25rem;
}
.goals__summary {
  display: flex;
  align-items: center;
}
.goals__aim {
  width: 100%;
}
.goals__print {
  margin-left: 1rem;
}
.goals__install-app {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.goals__qr {
  width: 3cm;
  margin-left: 1rem;
}

/* Goal */
.goal {
  margin: 2rem 0;
  padding-left: var(--input-padding);
}
.goal + .goal {
  margin-top: -2px;
}
.goal__input {
  width: 100%;
}
.goal__services {
  margin: 0;
}
.goal__services {
  list-style: none;
  padding-left: 0;
}
.goal__milestones {
  --milestone-gap: 1rem;
  --milestone-icon: 2.5rem;
  --milestone-half-icon: calc(var(--milestone-icon) / 2);
  display: flex;
  gap: var(--milestone-gap);
  margin-top: 1.25rem;
}

/* Choose Services */
.choose-services {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.choose-services__detail {
  flex: 1;
  width: 100%;
  padding: 2rem;
  padding-left: 1.5rem;
}
