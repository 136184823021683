.services-sidebar {
  flex: 0 1 30%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: 0;
  border-right: 1px solid var(--pink);
  overflow: hidden;
}
.services-sidebar:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.services-sidebar__filters {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-left: 2rem;
  margin-right: 1.5rem;
  border-bottom: 2px solid var(--lightgray);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.services-sidebar__result-count {
  display: block;
  margin: 0.25rem 0 1rem 0;
}

.services-sidebar__list {
  list-style: none;
  flex: 1;
  overflow-y: scroll;
}

.services-sidebar__sticky-footer {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0 2rem 1.25rem;
  background: white;
}

.services-status-icon {
  display: flex;
  justify-content: center;
  width: 2rem;
  font-size: 0.875rem;
}
