.service__header {
  display: flex;
  align-items: center;
}
.service__header-leading {
  flex: 1;
}
.service__stale-dot {
  margin-right: 0.5rem;
}
.service__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.social__network {
  display: flex;
  align-items: flex-start;
  margin: 0.75rem 0;
}
.social__icon {
  margin-top: 0.625rem;
  margin-right: 0.5rem;
}

.service-status {
  color: var(--hotpink);
}
