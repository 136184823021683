.shelf-service-management {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.shelf-service-management__header {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--pink);
}
.shelf-service-management__main {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.shelf-service-management__pagination-message {
  margin: 0.75rem 0;
}
.shelf-service-management__detail {
  flex: 1;
  padding: 2rem 0;
}
