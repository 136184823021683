.button,
button.button {
  /* TODO: use Tailwind, for now we have to override via button.button */
  display: inline-flex;
  gap: 0.25rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  padding: 0.5625rem 0.75rem;
  min-width: var(--min-interactive-touch);
  line-height: 1;
  font-size: 1rem;
  font-weight: bold;
  background: var(--interactive);
  color: white;
  cursor: pointer;
}
.button--icon {
  justify-content: center;
}
.button:active,
button.button:active {
  outline: none;
  filter: brightness(85%);
}
.button:disabled,
button.button:disabled {
  background: var(--gray);
  cursor: default;
}
.button--danger,
button.button--danger {
  background: var(--danger);
  color: var(--text-on-danger);
}
.button--secondary,
button.button--secondary {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background: transparent;
  color: var(--interactive);
}
.button--secondary:hover,
button.button--secondary:hover {
  background: rgba(0, 0, 0, 0.05);
}
.button--secondary:active,
button.button--secondary:active {
  background: rgba(0, 0, 0, 0.1);
}
.button--secondary:disabled,
button.button--secondary:disabled {
  background: transparent;
  color: var(--gray);
}
.button--secondary.button--danger,
button.button--secondary.button--danger {
  color: var(--danger);
}
.button--secondary.button--danger:disabled,
button.button--secondary.button--danger:disabled {
  opacity: 0.6;
}

/* ButtonGroup */
.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin: -0.25rem;
}
