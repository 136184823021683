.side-nav {
  position: sticky;
  top: 0;
  padding-top: 2rem;
}
.side-nav__link {
  @apply text-steelpink;
  display: block;
  padding: 0.375rem 0;
}
.side-nav__link:hover {
  text-decoration: underline;
}
.side-nav__sub {
  margin-left: 1em;
}
