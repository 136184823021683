.preview-service + .preview-service {
  margin-top: 2rem;
}
.preview-service__name {
  margin-top: 0;
}
.preview-service__text > p {
  line-height: 1.2;
  margin: 0 0 1em;
}
.preview-service__contact {
  list-style: none;
  margin: 0;
  padding: 0;
}
.preview-service__contact li {
  padding: 0.25rem 0;
  margin-bottom: 0.5rem;
}
.preview-service__contact-icon {
  color: #3f0099;
  margin-right: 1rem;
}
