.timeline {
  --activity-log-dot-size: 1rem;
  position: relative;
}
.timeline:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(var(--activity-log-dot-size) / 2);
  width: 0.125rem;
  height: 100%;
  transform: translateX(-50%);
  background: var(--hotpink);
}
.timeline__entry {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 2rem;
}
.timeline__entry:before {
  content: "";
  display: block;
  position: absolute;
  top: 1.625rem;
  left: 0;
  width: var(--activity-log-dot-size);
  height: var(--activity-log-dot-size);
  border: 0.125rem solid var(--hotpink);
  border-radius: 1000px;
  background: white;
}
.timeline__entry:first-child:before {
  background: var(--hotpink);
}
.timeline__overline {
  color: var(--hotpink);
}
.timeline__title {
  display: block;
  margin: 0.25rem 0 0.5rem;
}
.timeline__content {
  max-width: 38rem;
  text-align: justify;
}
